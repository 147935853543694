import { useMemo } from "react";
import useWindowSize from "./use-window-size";

export const useIsTablet = (breakpoint = 767, tabletBreakpoint = 1024) => {
  const windowSize = useWindowSize();
  const isTablet = useMemo(
    () => windowSize.width > breakpoint && windowSize.width <= tabletBreakpoint,
    [windowSize, breakpoint]
  );
  return isTablet;
};

export default useIsTablet;
