
export const useIsIE = () => {
  const getIsIE = () => {
    if (typeof window === "object") {
      let ieCheck = navigator.userAgent.match(/Trident/g);
      if (ieCheck !== null) {
        return true;
      }
      return false;
    } else {
      // console.log("inside else");
      return false;
    }
  }
  const isIE = getIsIE();
  return isIE;
};
export default useIsIE;
