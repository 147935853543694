import { useMemo } from "react";
import useWindowSize from "./use-window-size";

export const useIsMobile = (breakpoint = 767) => {
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => windowSize.width <= breakpoint, [
    windowSize,
    breakpoint,
  ]);
  return isMobile;
};

export default useIsMobile;
