import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable"; // For IE 11 support
import "core-js/features/object/keys";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";
import "core-js/es";
import "core-js/es/promise";
import "core-js/es/map";
import "core-js/es/set";
import "core-js/features/symbol";
import "raf/polyfill";
import "./utilities/client/polyfills";

import { BrowserRouter } from "react-router-dom";
import React from "react";
import { hydrate } from "react-dom";
import AppClient from "./AppClient";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

const breakpoint = 768;
const tabletBreakpoint = 1024;
const getSize = {
  width: window.innerWidth,
  height: window.innerHeight,
};

// Initial hydration
hydrate(
  <BrowserRouter>
    <AppClient />
  </BrowserRouter>,
  document.getElementById("root")
);

// const handleWindowResize = () => {
//   // console.log("Resized window");
//   hydrate(
//     <BrowserRouter>
//       <AppClient />
//     </BrowserRouter>,
//     document.getElementById("root")
//   );
// };

// window.addEventListener("resize", handleWindowResize);

if (module.hot) {
  module.hot.accept();
}
