import { useEffect, useState, useCallback } from 'react';

const useWindowSize = () => {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }), [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = (e) => {
      setWindowSize(getSize());
    };

    var userAgent = navigator.userAgent.toLowerCase(); 
    var Android = userAgent.indexOf("android") > -1; 
    var iPhone = userAgent.indexOf("iphone") > -1; 
    var iPad = userAgent.indexOf("ipad") > -1; 

    // console.log("userAgent", userAgent, Android, iPhone, iPad);
    if (Android || iPhone || iPad) {
      window.addEventListener('orientationchange', handleResize);
    } else {
      window.addEventListener('resize', handleResize);
    }    
    
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    }
  }, [isClient, getSize]);

  return windowSize;
};

export default useWindowSize;
