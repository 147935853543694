import styled from "styled-components";

const StyledLoader = styled.div`
  animation: loading 1s ease-in-out infinite;
  @keyframes loading {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  display: block;
  margin: 50px auto 20px auto;
  width: 100%;
  svg {
    width: 50px;
  }

  img {
    display: block;
    width: 50px;
  }

`;

const StyledLoaderCentered = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin: 0 auto;
  }
`;

export { StyledLoader, StyledLoaderCentered };
