import React from "react";
import { StyledLoader, StyledLoaderCentered } from "./styles";
import logo from "../../../public/images/logo2x.avif";

const Loader = ({ centered = false }) => {
  if (centered === false) {
    return (
      <StyledLoader>
        <img src={logo} alt=""/>
      </StyledLoader>
    );
  } else {
    return (
      <StyledLoaderCentered>
        <StyledLoader>
          <img src={logo} alt=""/>
        </StyledLoader>
      </StyledLoaderCentered>
    );
  }
};

export default Loader;
